@keyframes overlay-slide-in-right {
  0% {
    width:100%;
  }
  100% {
    width:0;
  }
}

.overlay-slide-in-right {
  animation-name: overlay-slide-in-right;
  width:0;
  right:0;
}

@keyframes overlay-slide-out-right {
  0% {
    width:0;
  }
  100% {
    width:100%;
  }
}

.overlay-slide-out-right {
  animation-name: overlay-slide-out-right;
  width:100%;
  right:0;
}
