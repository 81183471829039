@keyframes rotate-in-sm {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
    transform-origin: center center;
  }

  100% {
    opacity: 1;
    transform: rotate(0);
    transform-origin: center center;
  }
}

.rotate-in-sm {
  animation-name: rotate-in-sm;
}

@keyframes rotate-out-sm {
  0% {
    opacity: 1;
    transform: rotate(0);
    transform-origin: center center;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg);
    transform-origin: center center;
  }
}

.rotate-out-sm {
  animation-name: rotate-out-sm;
}
