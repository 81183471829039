@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-left {
  animation-name: fade-in-left;
}

@keyframes fade-out-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-500px);
  }
}

.fade-out-left {
  animation-name: fade-out-left;
}
