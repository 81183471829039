@keyframes overlay-slide-in-bottom {
  0% {
    height:100%;
  }
  100% {
    height:0;
  }
}
.overlay-slide-in-bottom {
  animation-name: overlay-slide-in-bottom;
  height:0;
  bottom:0;
}

@keyframes overlay-slide-out-bottom {
  0% {
    height:0;
  }
  100% {
    height:100%;
  }
}
.overlay-slide-out-bottom {
  animation-name: overlay-slide-out-bottom;
  height:100%;
  bottom:0;
}
