@keyframes fade-in-down-sm {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down-sm {
  animation-name: fade-in-down-sm;
}

@keyframes fade-out-down-sm {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

.fade-out-down-sm {
  animation-name: fade-out-down-sm;
}
