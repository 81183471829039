@keyframes flip-in-y-nr {
  0% {
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }
}
.flip-in-y-nr {
  animation-name: flip-in-y-nr;
  backface-visibility: visible !important;
}

@keyframes flip-out-y-nr {
  0% {
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
}
.flip-out-y-nr {
  animation-name: flip-out-y-nr;
  backface-visibility: visible !important;
}
