@keyframes fade-in-up-lg {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up-lg {
  animation-name: fade-in-up-lg;
}

@keyframes fade-out-up-lg {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px);
  }
}

.fade-out-up-lg {
  animation-name: fade-out-up-lg;
}
