@keyframes zoom-in-sm {
  0% {
    opacity: 0;
    transform: scale(.95);
  }
  100% {
    opacity: 1;
  }
}
.zoom-in-sm {
  animation-name: zoom-in-sm;
}

@keyframes zoom-out-sm {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(.95);
  }
  100% {
    opacity: 0;
  }
}
.zoom-out-sm {
  animation-name: zoom-out-sm;
}
