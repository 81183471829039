@keyframes zoom-in-lg {
  0% {
    opacity: 0;
    transform: scale(.4);
  }
  100% {
    opacity: 1;
  }
}
.zoom-in-lg {
  animation-name: zoom-in-lg;
}

@keyframes zoom-out-lg {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(.4);
  }
  100% {
    opacity: 0;
  }
}
.zoom-out-lg {
  animation-name: zoom-out-lg;
}
